@import "variables";
@import "mixins";

.alternative-pages {
  .headline-section {
    background: 50% 50% url("/static/img/Background-alternative.png") no-repeat;
    background-size: cover;
    height: 630px;
    min-height: 0;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #1d3c63; /* Old browsers */
      background: -moz-radial-gradient(center, ellipse cover, #0F7180 1%, #1d3c63 100%);
      background: -webkit-radial-gradient(center, ellipse cover, #0F7180 1%,#1d3c63 100%);
      background: radial-gradient(ellipse at center, #0F7180 1%,#1d3c63 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0F7180', endColorstr='#1d3c63',GradientType=1 );
      @include opacity(0.6);
    }
    h2 {
      color: white;
    }
  }
  .content {
    margin-top: 30px;
    padding-bottom: 0;
    .contact-form.tile {
      background-color: white;
      max-width: 350px;
      margin: 0 auto;
      border: 0;
      display: block;
      .form-header {
        margin-top: -5px;
      }
    }
    .alert {
      max-width: 350px;
      margin: 0 auto 10px auto;
      .inner {
        display: block;
        border: 1px solid rgba(255,255,255,0.1);
        img {
          margin-top: -2px;
        }
      }
    }
  }
  .scewed-edge {
    @include scewed-edge(#f3f6f8);
  }
}

.pay {
  .cards {
    float: right;
    margin: -8px -8px 0 0;
    height: 28px;
    width: auto;
  }
  .content .contact-form.tile {
    max-width: 740px;
    padding: 0;
    .pay-half,
    .what-you-get {
      padding: 20px 10px 20px 20px;
    }
    .what-you-get {
      @include border-radius(0 7px 7px 0);
      padding-right: 35px;
      background-color: #F4F4F4;
      margin-left: -0.75rem;
      .rocket {
        position: absolute;
        bottom: -15px;
        right: -55px;
      }
    }
    .row {
      margin-bottom: 0;
    }
    .expires-row {
      margin-top: -10px;
    }
    .caption {
      margin: 5px 0;
      img {
        vertical-align: top;
        margin: -1px 3px 0 0;
      }
    }
    .card-expires {
      input.month,
      input.year {
        width: 50%;
        text-align: center;
      }
      input.month {
        @include border-radius(5px 0 0 5px);
        float: left;
      }
      input.year {
        @include border-radius(0 5px 5px 0);
        float: right;
      }
    }
    .total-amount-from-months {
      font-size: 1.7em;
      margin-left: -1px;
    }
    select.months {
      margin-bottom: 0;
    }
    &.confirm-payment {
      text-align: center;
      max-width: 480px;
      .top-tab {
        @include border-radius(7px 7px 0 0);
        background-color: #F4F4F4;
        padding: 24px 24px 14px 24px;
      }
      .cta {
        max-width: 230px;
        margin: 0 auto 15px auto;
        display: block;
        float: none !important;
      }
      a.back {
        color: $call-to-action-colour;
        img {
          margin: 6px 8px 0 0;
          vertical-align: top;
          width: 8px;
          height: auto;
        }
      }
      .form-header {
        font-weight: normal;
        text-align: center;
      }
      .total-amount {
        color: inherit;
      }
      .confirm-action {
        padding: 24px;
      }
    }
  }
  .pay-response {
    .tile {
      text-align: center;
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      h4 {
        @include border-radius(7px 7px 0 0);
        padding: 25px;
        font-weight: 400;
        background: #F1F1F1 bottom -1px left 0 url(/static/img/zigzag-edge.svg) repeat-x;
      }
      .success,
      .unsuccessful {
        font-size: 1.2em;
        display: inline-block;
        margin: 15px 15px 22px 15px;
      }
      .success {
        color: $primary-green;
        .round-tick {
          width: 40px;
          height: 40px;
          border-width: 3px;
        }
        strong {
          margin: 8px 0 0 10px;
          display: inline-block;
        }
      }
      .success-stars {
        position: absolute;
        bottom: 22px;
        left: 34px;
        right: 20px;
        width: 88%;
      }
      .unsuccessful {

      }
    }
  }
}

.crop-insight {
  .content {
    .tile {
      max-width: 800px;
      margin: 0 auto 30px auto;
      padding-bottom: 0;
      .circle {
        border: 2px solid $primary-colour-1;
        width: 170px;
        height: 170px;
        position: absolute;
        left: -80px;
        top: 15px;
        background: white;
        text-align: center;
        z-index: 100;
        img {
          margin-top: 37px;
        }
      }
      h4 {
        margin-left: 134px;
        font-weight: 400;
        margin-bottom: 20px;
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 47px;
        }
        &:before { /* line from big icon to little circle */
          top: 47px;
          left: 0;
          z-index: 99;
          height: 2px;
          background-color: $primary-colour-1;
          width: 120px;
        }
        &:after { /* little circle next to titles */
          @include border-radius(50%);
          border: 2px solid $primary-colour-1;
          margin-top: -7px;
          left: 117px;
          z-index: 100;
          width: 18px;
          height: 18px;
          background-color: white;
        }
      }
      ul {
        padding-left: 90px;
        li {
          @include border-radius(3px);
          background-color: #F1F1F1;
          margin-top: 6px;
          padding: 3px 5px 4px 45px;
          position: relative;
          z-index: 100;
          img {
            @include centralise;
            left: 18px;
          }
        }
      }
      .map-photo {
        @include border-radius(4px);
        position: relative;
        border: 1px solid $primary-colour-1;
        width: 100%;
        max-width: 170px;
        height: 170px;
        float: right;
        margin-bottom: 12px;
        &:before { /* dashed line from map photo to list item */
          content: '';
          position: absolute;
          left: -110px;
          top: 79px;
          right: 10px;
          border-bottom: 1px dashed $primary-colour-1;
          z-index: 99;
        }
        img {
          width: 100%;
          height: 100%;
          z-index: 100;
          position: relative;
        }
        .map-photo-caption {
          @include border-radius(2px);
          @include tile-box-shadow;
          font-size: 0.8em;
          line-height: 1.2em;
          text-align: center;
          position: absolute;
          bottom: 5px;
          left: -5px;
          right: -5px;
          background-color: white;
          padding: 4px;
          z-index: 101;
        }
      }
      .map-photo:nth-child(3):before {
        top: 46px;
      }
      .map-photo:last-child {
        margin-bottom: 0;
      }
    }
    .tile:nth-child(2) { /* plant health */
      .circle img {
        margin-top: 40px;
      }
    }
    .tile:nth-child(3) { /* farm operations */
      .circle img {
        margin-top: 42px;
      }
    }
  }
}


@media (max-width: 991px) {
  .crop-insight .content {
    .tile {
      .circle {
        width: 140px;
        height: 140px;
        left: -65px;
        img {
          width: 50%;
          height: auto;
          margin-top: 30px;
        }
      }
      ul {
        padding-left: 70px;
      }
      h4 {
        margin-left: 114px;
        &:before {
          width: 100px;
        }
        &:after {
          left: 97px;
        }
      }
      .map-photo:nth-child(3):before {
        top: 40px;
      }
    }
    .tile:nth-child(2) { /* plant health */
      .circle img {
        width: 60%;
        margin-top: 35px;
      }
    }
  }
  .pay .content {
    .contact-form.tile {
      .what-you-get .list-item:last-child {
        padding-right: 50px;
      }
    }
  }
}
@media (max-width: 767px) {
  .pay {
    label[for="amount"] {
      margin-top: 5px;
    }
  }
  .pay .what-you-get {
    .about-me-text {
      margin-left: 25px;
      line-height: 1.3em;
    }
    .about-me-list .list-item {
      padding-left: 0;
      .round-tick {
        margin: 2px 0 0 -3px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .alternative-pages .content {
    margin-top: 20px;
  }
}
@media (min-width: 601px) {
  .pay .content .contact-form.tile .what-you-get {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}
@media (min-width: 601px) and (max-width: 767px) {
  .pay .container {
    width: 100%;
    .content .contact-form.tile {
      .caption {
        margin-left: -2px;
        img {
          display: none;
        }
      }
      .what-you-get {
        .rocket {
          width: 130px;
          right: -20px;
          bottom: -13px;
        }
        .about-me-list .list-item:nth-child(3),
        .about-me-list .list-item:nth-child(4) {
          padding-right: 50px;
        }
      }
    }
    .card-expires input.year {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
@media (max-width: 750px) {
  .crop-insight .content {
    margin-top: 50px;
    .tile {
      margin-bottom: 50px;
      .map-photo:before,
      h4:before,
      h4:after {
        display: none;
      }
      .circle {
        left: 50%;
        top: -38px;
        width: 78px;
        height: 78px;
        margin-left: -39px;
        img {
          margin-top: 20px;
        }
      }
      ul {
        padding-left: 0;
      }
      h4 {
        margin: 30px 0 20px 0;
      }
    }
    .tile:nth-child(2) { /* plant health */
      .circle img {
        margin-top: 22px;
      }
    }
    .tile:nth-child(3) { /* farm operations */
      .circle img {
        margin-top: 24px;
        width: 54%;
      }
    }
  }
}
@media (max-width: 600px) {
  .pay .content .contact-form.tile .pay-half,
  .pay .content .contact-form.tile .what-you-get {
    padding: 20px;
  }
  .pay .content .contact-form.tile .what-you-get {
    @include border-radius(0 0 7px 7px);
    margin-left: 0;
    min-height: 0;
    .list-item {
      padding-left: 0;
    }
  }
  .crop-insight .content {
    margin-top: 60px;
    .tile {
      text-align: center;
      .map-photo {
        margin: 0 8px 10px 8px;
        float: none;
        display: inline-block;
      }
      ul {
        margin-bottom: 25px;
        li {
          text-align: left;
        }
      }
    }
  }
  .crop-insight .container.main {
    padding-top: 20px;
  }
}
@media (max-width: 479px) {
  .pay .content .contact-form.tile {
    .caption {
      margin-left: -2px;
      img {
        display: none;
      }
    }
  }
  .pay .content .contact-form.tile .what-you-get {
    .rocket {
      right: -20px;
      width: 100px;
      overflow: hidden;
      img {
        width: 130px;
        height: auto;
        max-width: none;
      }
    }
    .list-item:last-child {
      padding-right: 30px;
    }
  }
}